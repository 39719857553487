import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";

const Tags = ({ tags }: { tags: string[] }): JSX.Element => {
  return (
    <Box>
      {/*<Typography*/}
      {/*  variant="h4"*/}
      {/*  align={"center"}*/}
      {/*  sx={{*/}
      {/*    fontWeight: 700,*/}
      {/*    marginBottom: 2,*/}
      {/*  }}*/}
      {/*>*/}
      {/*  Tag cloud*/}
      {/*</Typography>*/}
      <Box display={"flex"} flexWrap={"wrap"} justifyContent={"left"}>
        {tags.map((tag) => (
          <Chip
            key={tag}
            label={tag}
            sx={{ margin: 0.5, color: "#2D3748", backgroundColor: "#c1c1c2" }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Tags;
