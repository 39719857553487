/* eslint-disable react/no-unescaped-entities */
import React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
import { getI18n, Trans, useTranslation } from "react-i18next";
import { StaticImage } from "gatsby-plugin-image";

const APPSTORE = new Map([
  [
    "en",
    <StaticImage src={"./appstore_en.png"} height={56} alt="app store url" />,
  ],
  [
    "ja",
    <StaticImage src={"./appstore_ja.png"} height={56} alt="app store url" />,
  ],
]);

const PLAYMARKET = new Map([
  [
    "en",
    <StaticImage
      src={"./playmarket_en.png"}
      height={77}
      style={{ margin: "-12px" }}
      alt="play market url"
    />,
  ],
  [
    "ja",
    <StaticImage
      src={"./playmarket_ja.png"}
      height={77}
      style={{ margin: "-12px" }}
      alt="play market url"
    />,
  ],
]);

const SidebarInfo = ({
  client,
  releaseDate,
  appStoreUrl,
  playStoreUrl,
}: {
  client: string;
  releaseDate: string;
  appStoreUrl: string;
  playStoreUrl: string;
}): JSX.Element => {
  const { t } = useTranslation();
  const i18n = getI18n();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {client && (
        <>
          <Typography
            variant="body1"
            data-aos={"fade-up"}
            sx={{
              fontWeight: 400,
              color: "#646E73",
            }}
          >
            <Trans>client</Trans>
          </Typography>
          <Typography
            variant="h6"
            data-aos={"fade-up"}
            sx={{
              fontWeight: 600,
              marginBottom: 2,
            }}
          >
            {client}
          </Typography>
        </>
      )}
      <Typography
        variant="body1"
        data-aos={"fade-up"}
        sx={{
          fontWeight: 400,
          color: "#646E73",
        }}
      >
        <Trans>release date</Trans>
      </Typography>
      <Typography
        variant="h6"
        data-aos={"fade-up"}
        sx={{
          fontWeight: 600,
          marginBottom: 2,
        }}
      >
        {releaseDate}
      </Typography>
      {appStoreUrl && (
        <Link
          sx={{ display: "block", mt: "0.7rem" }}
          target="_blank"
          href={appStoreUrl}
        >
          {APPSTORE.get(i18n.language)}
        </Link>
      )}
      {playStoreUrl && (
        <Link
          sx={{ display: "block", mt: ".9rem" }}
          target="_blank"
          href={playStoreUrl}
        >
          {PLAYMARKET.get(i18n.language)}
        </Link>
      )}
    </Box>
  );
};

export default SidebarInfo;
